import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SyncLoader from 'react-spinners/SyncLoader';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

function TotalCount({ endpoint, title }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`
        );

        setData(response.data.results);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log('Error', error.message);
        }
      }
    }

    fetchData();
  }, []);

  return isLoading ? (
    <>
      <div className='wrapper'>
        <SyncLoader color='#8884d8' />
      </div>
    </>
  ) : (
    <>
      <div className='wrapper shadowed'>
        <Row className='mx-auto'>
          <Card className='border-0 my-0'>
            <Card.Body>
              <p className='chartTitle mt-2'>{title}</p>
              <Card.Text className='my-5 fw-bold fs-2' style={{ paddingTop: 100 }}>
                {data}
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </div>
    </>
  );
}

export default TotalCount;
