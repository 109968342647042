import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import SyncLoader from 'react-spinners/SyncLoader';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ExcelButton from '../General/ExcelButton';

dayjs.extend(quarterOfYear);

function NoChart({ title, endpoint, searchEndpoint }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParameters, setQueryParameters] = useState([]);
  const [isError, setIsError] = useState(false);
  const [presentersAPIData, setPresentersAPIData] = useState([]);
  const [currentPresenter, setCurrentPresenter] = useState('');
  const [currentPresenterName, setCurrentPresenterName] = useState('');
  const [isRelatedVideosFetched, setIsRelatedVideosFetched] = useState(false);

  const handleChangeInputType = (event, inputType) => {
    if (!event.target.value) event.target.type = inputType;
  };

  const handleDate = event => {
    const date = dayjs(event.target.value).format('YYYY-MM-DD');
    setQueryParameters({ ...queryParameters, [event.target.name]: date });
  };

  const handlePresenter = event => {
    setQueryParameters({ ...queryParameters, [event.target.name]: event.target.value });
    setCurrentPresenter(+event.target.value);
  };

  const handleNoChartData = async () => {
    if (queryParameters?.from > queryParameters?.to) {
      setIsError(true);
      return;
    }
    try {
      let presenterName = presentersAPIData?.find(
        presenter => presenter.id === currentPresenter
      )?.name;
      setIsError(false);
      setIsLoading(true);
      let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${searchEndpoint}`, {
        params: { ...queryParameters },
      });
      setData(response.data.results[0].media);
      setCurrentPresenterName(presenterName);
      setIsLoading(false);
      setIsRelatedVideosFetched(true);
      setCurrentPresenter('');
      setQueryParameters([]);
    } catch (error) {
      if (error.response) {
        // Request made but the server responded with an error
        console.log(error.response.data);
      } else if (error.request) {
        // Request made but no response is received from the server.
        console.log(error.request);
      } else {
        // Error occured while setting up the request
        console.log('Error', error.message);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`);

        setPresentersAPIData(response.data.results);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log('Error', error.message);
        }
      }
    }

    fetchData();
  }, []);

  return isLoading ? (
    <>
      <div className='wrapper'>
        <SyncLoader color='#8884d8' />
      </div>
    </>
  ) : (
    <>
      <div className='wrapper'>
        <div className='mx-auto bg-white w-75'>
          <Row className='m-0'>
            <Col className='py-2'>
              <span className='chartTitle mt-4'>{title}</span>
            </Col>
          </Row>
          <Row className='justify-content-center mx-0 bg-white'>
            <InputGroup className='w-75 align-items-baseline'>
              <Form.Control
                aria-label='From'
                type='text'
                id='from'
                name='from'
                className='mx-1'
                onChange={handleDate}
                onFocus={event => handleChangeInputType(event, 'date')}
                onBlur={event => handleChangeInputType(event, 'text')}
                placeholder='From'
                max={dayjs().format('YYYY-MM-DD')}
              />
              <Form.Control
                aria-label='To'
                type='text'
                id='to'
                name='to'
                className='mx-1'
                onChange={handleDate}
                onFocus={event => handleChangeInputType(event, 'date')}
                onBlur={event => handleChangeInputType(event, 'text')}
                placeholder='To'
                max={dayjs().format('YYYY-MM-DD')}
              />
              <Form.Select
                aria-label='Default select example'
                name='presenter'
                onChange={handlePresenter}>
                <option>Select Presenter</option>
                {presentersAPIData?.map(presenter => (
                  <option value={presenter?.id} key={presenter?.id}>
                    {presenter?.name}
                  </option>
                ))}
              </Form.Select>
              <Button
                variant='secondary'
                id='button-addon1'
                onClick={handleNoChartData}
                className='mx-2'
                disabled={
                  !(queryParameters?.from && queryParameters?.to && queryParameters?.presenter)
                }>
                Search
              </Button>
              <ExcelButton
                data={data}
                name={currentPresenterName}
                isGreenVariant={true}
                firstColumn='title'
                secondColumn='views'
                isOtherfieldsPresent={false}
                isNoChart={true}
              />
            </InputGroup>
          </Row>
          <Row className='justify-content-center'>
            {isError && (
              <Alert
                className='w-50 my-1'
                variant='danger'
                onClose={() => setIsError(false)}
                dismissible>
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>"From" date should be less than "To" date, please try again.</p>
              </Alert>
            )}
          </Row>
          {isRelatedVideosFetched && (
            <Row className='justify-content-center my-3 fw-bold fs-5'>
              {`Videos Related to ${currentPresenterName} are successfully fetched, ${
                data?.length
              } video${data?.length !== 1 ? 's' : ''} ${data?.length !== 1 ? 'are' : 'is'} found`}
            </Row>
          )}
        </div>
      </div>
    </>
  );
}

export default NoChart;
