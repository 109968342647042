import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Cookies from 'js-cookie';

import DataContext from '../../context/data';
import users from '../../users.json';

function Login() {
  let navigate = useNavigate();

  const { setIsAuthenticated } = useContext(DataContext);
  const [loginData, setLoginData] = useState({});
  const [isError, setIsError] = useState(false);

  const handleChange = event => {
    setLoginData({ ...loginData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setIsError(false);
    if (
      users.data?.some(
        user => user.email === loginData?.email && user.password === loginData?.password
      )
    ) {
      Cookies.set('isAuthenticated', true, { expires: 1 });
      setIsAuthenticated(Cookies.get('isAuthenticated'));
      navigate('/home');
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      {isError && (
        <ToastContainer className='p-3' position='top-end' style={{ zIndex: 1 }}>
          <Toast onClose={() => setIsError(false)} show={isError} delay={3000} autohide>
            <Toast.Header>
              <strong className='me-auto'>Error</strong>
            </Toast.Header>
            <Toast.Body>Please check your credentials.</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <div className='container d-flex flex-column justify-content-center align-items-center'>
        <div className='row p-auto mt-3 mb-1'>
          <img src='/logo.jpg' alt='Dashboard Logo' className='mx-auto' style={{ width: '30%' }} />
        </div>
        <div className='row my-5'>
          <form onSubmit={handleSubmit}>
            <div className='row mb-3'>
              <label htmlFor='email' className='form-label'>
                Email
              </label>
              <input
                type='email'
                className='form-control'
                id='email'
                name='email'
                onChange={handleChange}
              />
            </div>
            <div className='row mb-3'>
              <label htmlFor='password' className='form-label'>
                Password
              </label>
              <input
                type='password'
                className='form-control'
                id='password'
                name='password'
                onChange={handleChange}
              />
            </div>
            <div className='row'>
              <button type='submit' className='btn btn-primary w-25 mx-auto'>
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
