import React from 'react';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';

function ExcelButton({
  data,
  name,
  firstColumn,
  secondColumn,
  isOtherfieldsPresent,
  isPresenter,
  isNoChart,
  isGreenVariant
}) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const handleExportDataToExcel = () => {
    const header = isOtherfieldsPresent
      ? isPresenter
        ? [firstColumn, 'lead ', secondColumn, 'services', 'presenters_list']
        : [firstColumn, 'lead ', secondColumn, 'services']
      : isNoChart
      ? [firstColumn, 'lead', secondColumn]
      : [firstColumn, secondColumn];
    const workSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(workSheet, [header], { origin: 'A1' });

    // Add cell styles to the header row
    for (let i = 0; i < header.length; i++) {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: i });
      if (!workSheet[cellRef]) workSheet[cellRef] = {};
      workSheet[cellRef].s = {
        font: { bold: true },
        fill: { fgColor: { rgb: 'D3D3D3' } },
        alignment: {
          horizontal: 'center',
        },
      };
    }

    // Calculate the sum of all columns
    const columnSums = {};
    header.forEach(columnName => {
      if (columnName === secondColumn) {
        let columnSum = 0;
        data?.forEach(row => {
          columnSum += row[columnName];
        });
        columnSums[columnName] = columnSum;
      }
    });

    const totalRow = [];
    header.forEach(columnName => {
      if (columnName === firstColumn) {
        totalRow.push('Total');
      } else {
        totalRow.push(columnSums[columnName]);
      }
    });

    // Add empty row space between table and totalRow
    XLSX.utils.sheet_add_aoa(workSheet, [[]], { origin: -1 });

    XLSX.utils.sheet_add_aoa(workSheet, [totalRow], { origin: -1 });

    let range = XLSX.utils.decode_range(workSheet['!ref']);
    let num_rows = range.e.r - range.s.r;

    // Add cell styles to the 'Total' row
    // TODO: here we use totalRow.length instead of totalRow.length -1 as there is no totla column in the sheet
    for (let i = 0; i < totalRow.length; i++) {
      const cellRef = XLSX.utils.encode_cell({ r: num_rows, c: i });
      if (!workSheet[cellRef]) workSheet[cellRef] = {};
      workSheet[cellRef].s = {
        font: { bold: true },
        fill: { fgColor: { rgb: 'D3D3D4' } },
        alignment: {
          horizontal: 'center',
        },
      };
    }

    const workBook = { Sheets: { [name]: workSheet }, SheetNames: [name] };
    const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, name + fileExtension);
  };
  return (
    <>
      <button
        className={`button ${isGreenVariant ? 'excel' : 'subscribed'} mx-3 my-2`}
        onClick={handleExportDataToExcel}>
        <FaFileExcel />
      </button>
    </>
  );
}

export default ExcelButton;
