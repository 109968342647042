import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'js-cookie';

import Login from './components/General/Login';
import Home from './components/General/Home';

import DataContext from './context/data';

function App() {
  let token = Cookies.get('isAuthenticated');
  const [isAuthenticated, setIsAuthenticated] = useState(token);
  return (
    <div className='App'>
      <DataContext.Provider
        value={{
          isAuthenticated,
          setIsAuthenticated,
        }}>
        {isAuthenticated ? (
          <Routes>
            <Route path='/home' element={<Home />}></Route>
            <Route path='*' element={<Navigate to='/home' replace />}></Route>
          </Routes>
        ) : (
          <Routes>
            <Route path='/' element={<Login />}></Route>
            <Route path='*' element={<Navigate to='/' replace />}></Route>
          </Routes>
        )}
      </DataContext.Provider>
    </div>
  );
}

export default App;
