import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import SyncLoader from 'react-spinners/SyncLoader';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import ExcelButton from '../General/ExcelButton';

function Chart({ endpoint, title }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_DEV_BASEURL}/totalHits/${endpoint}`
        );

        setData(response.data.results);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log('Error', error.message);
        }
      }
    }

    fetchData();
  }, []);

  return isLoading ? (
    <>
      <div className='wrapper'>
        <SyncLoader color='#8884d8' />
      </div>
    </>
  ) : (
    <>
      <div className='wrapper shadowed'>
        <div className='chartContainer mx-auto bg-white'>
          <Row className='m-0'>
            <Col xs={9} className='py-2'>
              <span className='chartTitle mt-4'>{title}</span>
            </Col>
            <Col className='text-start'>
              <ExcelButton
                data={data}
                name={title}
                isGreenVariant={true}
                firstColumn='Day/Month'
                secondColumn='Count'
                isOtherfieldsPresent={false}
              />
            </Col>
          </Row>
          {data.length > 0 ? (
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart
                width={1200}
                height={600}
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 30,
                  bottom: 60,
                }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip />
                <Line type='monotone' dataKey='Count' stroke='#8884d8' />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Row className='m-0 h-75 align-content-center my-5'>
              <p className='fs-5 fw-bold mt-4'>sorry, no data available to show right now</p>
            </Row>
          )}
        </div>
      </div>
    </>
  );
}

export default Chart;
