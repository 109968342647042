import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Chart from '../Charts/Chart';
import PeriodicChart from '../Charts/PeriodicChart';
import TopTwentyMediaChart from '../Charts/TopTwentyMediaChart';
import TotalCount from '../Cards/TotalCount';
import NoChart from '../Charts/NoChart';

function Home() {
  return (
    <Container fluid>
      <Row className='h-100 my-1 pt-4 justify-content-center align-content-center'>
        <p className='my-auto fs-2 fw-bolder text-capitalize'>welcome to dashboard</p>
      </Row>
      <Row className='my-2'>
        <Col xs={9}>
          <Chart endpoint='month' title='current month total hits' />
        </Col>
        <Col>
          <TotalCount endpoint='totalCount/month' title='current month count' />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <TotalCount endpoint='totalCount/currentWeek' title='current week count' />
        </Col>
        <Col xs={9}>
          <Chart endpoint='currentWeek' title='current week total hits' />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col xs={9}>
          <Chart endpoint='lastWeek' title='last week total hits' />
        </Col>
        <Col>
          <TotalCount endpoint='totalCount/lastWeek' title='last week count' />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <PeriodicChart
            endpoint='totalHits/period'
            title='period total hits'
            isSubpageSelectHidden={true}
            isSubscribedCheckHidden={true}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <PeriodicChart
            endpoint='session/users'
            title='unique user sessions'
            isSubpageSelectHidden={true}
            isSubscribedCheckHidden={true}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <PeriodicChart
            endpoint='failureRate/headerEnrichment'
            title='header enrichment failure rate'
            isSubpageSelectHidden={true}
            isSubscribedCheckHidden={true}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <PeriodicChart
            endpoint='failureRate/portal'
            title='portal failure rate'
            isSubpageSelectHidden={true}
            isSubscribedCheckHidden={true}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <PeriodicChart
            endpoint='totalHits/page'
            title='subpage total hits'
            isSubpageSelectHidden={false}
            isSubscribedCheckHidden={false}
            isSubpageSubscribersbuttonNeeded={true}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <PeriodicChart
            endpoint='session/page'
            title='unique subpage users'
            isSubpageSelectHidden={false}
            isSubscribedCheckHidden={false}
            isSubpageSubscribersbuttonNeeded={false}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <TopTwentyMediaChart
            endpoint='videos'
            title='top 20 videos'
            isPresenterFieldNeeded={true}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <TopTwentyMediaChart
            endpoint='articles'
            title='top 20 articles'
            isPresenterFieldNeeded={false}
          />
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          <NoChart
            endpoint='presenters/all'
            searchEndpoint='presenters/relatedVideos'
            title='presenters'
          />
        </Col>
      </Row>
      <Row className='my-2 justify-content-evenly'>
        <Col xs={3}>
          <TotalCount endpoint='totalCount/qr' title='qr scan total count' />
        </Col>
        <Col xs={3}>
          <TotalCount endpoint='session/qr' title='unique qr scan users' />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
